// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/LogoJoãoPessoaFitOK.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_home__I2ZGT {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.style_content__DKkYb {
  display: flex;
}

.style_profile__9jn9\\+ {
  width: 50vw;
  height: calc(100vh - 87px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.style_links__ve42T {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.style_link__4axLu {
  background-color:#012a50;
  border-radius: 15px;
  border-style: solid;
  border-color: #012a50;
  width: 250px;
  padding: 8px;
  text-align: center;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 32px;
  color: white;
  text-decoration: none;
  
}


.style_logo__RDu0b {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_logoFit__D0pkT {
  width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/criarTreinos/style.module.css"],"names":[],"mappings":"AAAA;EACE,yDAA6D;EAC7D,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,YAAY;EACZ,qBAAqB;;AAEvB;;;AAGA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".home {\n  background-image: url(\"../../assets/LogoJoãoPessoaFitOK.png\");\n  width: 100vw;\n  height: 100vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.content {\n  display: flex;\n}\n\n.profile {\n  width: 50vw;\n  height: calc(100vh - 87px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: white;\n}\n\n.links {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  align-items: center;\n}\n\n.link {\n  background-color:#012a50;\n  border-radius: 15px;\n  border-style: solid;\n  border-color: #012a50;\n  width: 250px;\n  padding: 8px;\n  text-align: center;\n  font-weight: 400;\n  letter-spacing: -1px;\n  font-size: 32px;\n  color: white;\n  text-decoration: none;\n  \n}\n\n\n.logo {\n  width: 50vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.logoFit {\n  width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `style_home__I2ZGT`,
	"content": `style_content__DKkYb`,
	"profile": `style_profile__9jn9+`,
	"links": `style_links__ve42T`,
	"link": `style_link__4axLu`,
	"logo": `style_logo__RDu0b`,
	"logoFit": `style_logoFit__D0pkT`
};
export default ___CSS_LOADER_EXPORT___;
