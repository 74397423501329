// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_home__0by\\+N {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.style_container__Yc3Ao  {
  height: calc(100vh - 87px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.style_criarTreinoLink__aaCoJ {
    position: absolute;
    right: 7vw;
    bottom: 40px;
    background-color: #012a50;
    border-radius: 80px;
    width: 130px;
    padding: 15px 20px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: white;
    text-decoration: none;
}

.style_criarTreinoLink__aaCoJ:hover{
    color: #012a50;
    background-color: #fff;
}

.style_logoJP__m7sjj {
  height: 20vw;
}

.style_logoFit__Nr-sQ {
  height: 8vw;
}


/*table*/
@media (max-width: 1024px) {
  
}

/*mobile*/
@media (max-width: 767px) {
  
}`, "",{"version":3,"sources":["webpack://./src/pages/home/style.module.css"],"names":[],"mappings":"AAAA;EACE,yDAA2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;;AAGA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;;AAGA,QAAQ;AACR;;AAEA;;AAEA,SAAS;AACT;;AAEA","sourcesContent":[".home {\n  background-image: url(\"../../assets/2.png\");\n  height: 100vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.container  {\n  height: calc(100vh - 87px);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n\n.criarTreinoLink {\n    position: absolute;\n    right: 7vw;\n    bottom: 40px;\n    background-color: #012a50;\n    border-radius: 80px;\n    width: 130px;\n    padding: 15px 20px;\n    text-align: center;\n    font-weight: 700;\n    font-size: 20px;\n    color: white;\n    text-decoration: none;\n}\n\n.criarTreinoLink:hover{\n    color: #012a50;\n    background-color: #fff;\n}\n\n.logoJP {\n  height: 20vw;\n}\n\n.logoFit {\n  height: 8vw;\n}\n\n\n/*table*/\n@media (max-width: 1024px) {\n  \n}\n\n/*mobile*/\n@media (max-width: 767px) {\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `style_home__0by+N`,
	"container": `style_container__Yc3Ao`,
	"criarTreinoLink": `style_criarTreinoLink__aaCoJ`,
	"logoJP": `style_logoJP__m7sjj`,
	"logoFit": `style_logoFit__Nr-sQ`
};
export default ___CSS_LOADER_EXPORT___;
