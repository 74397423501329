// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__cPtij {
  height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_img__ZJpDH {
  width: 400px;
}

.style_aluno__ia0IZ {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.style_aluno__ia0IZ img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.style_aluno__ia0IZ h3 {
  width: 100%;
  min-width: 108px;
  color: #012a50;
  font-size: 16px;
}

.style_treino__qDZON h2 {
  color: #fff;
  background-color: #012a50;
  margin: 0;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
}

.style_treino__qDZON {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/visualizar/style.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,SAAS;EACT,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".container {\n  height: calc(100vh - 74px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.img {\n  width: 400px;\n}\n\n.aluno {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.aluno img {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n}\n\n.aluno h3 {\n  width: 100%;\n  min-width: 108px;\n  color: #012a50;\n  font-size: 16px;\n}\n\n.treino h2 {\n  color: #fff;\n  background-color: #012a50;\n  margin: 0;\n  border-radius: 10px;\n  padding: 5px 10px;\n  text-align: center;\n}\n\n.treino {\n  background-color: white;\n  padding: 10px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__cPtij`,
	"img": `style_img__ZJpDH`,
	"aluno": `style_aluno__ia0IZ`,
	"treino": `style_treino__qDZON`
};
export default ___CSS_LOADER_EXPORT___;
