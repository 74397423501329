// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-input-placeholder {
    color: darkgray;
    font-size: 16px;
  }

  .style_input__-nUYH {
    padding: 15px;
    border-radius: 100px;
    border: 0;
    font-weight: 400;
  }`, "",{"version":3,"sources":["webpack://./src/components/input/style.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,oBAAoB;IACpB,SAAS;IACT,gBAAgB;EAClB","sourcesContent":["::-webkit-input-placeholder {\n    color: darkgray;\n    font-size: 16px;\n  }\n\n  .input {\n    padding: 15px;\n    border-radius: 100px;\n    border: 0;\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `style_input__-nUYH`
};
export default ___CSS_LOADER_EXPORT___;
