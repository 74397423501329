// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/5.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_profilePage__rPu48 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.style_container__c2D0p {
  height: calc(100vh - 87px);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.style_content__e2xYy {

  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.style_logo__iZp-b {
  width: 250px;
}

.style_password__3ijNF {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  gap: 20px;
}

.style_link__v1o4F {
  background-color: #fff;
  border-radius: 15px;
  width: 130px;
  padding: 8px;
  text-align: center;
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 20px;
  color: #012a50;
  text-decoration: none;
}

@media (max-width: 767px) {
  .style_password__3ijNF {
    width: 250px;
  }

  .style_logo__iZp-b {
    margin-top: -50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/security/style.module.css"],"names":[],"mappings":"AAAA;EACE,yDAA2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;EAC1B,WAAW;;EAEX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".profilePage {\n  background-image: url(\"../../assets/5.png\");\n  height: 100vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.container {\n  height: calc(100vh - 87px);\n  width: 100%;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n\n  display: flex;\n  flex-direction: column;\n  width: 400px;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n}\n\n.logo {\n  width: 250px;\n}\n\n.password {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 350px;\n  gap: 20px;\n}\n\n.link {\n  background-color: #fff;\n  border-radius: 15px;\n  width: 130px;\n  padding: 8px;\n  text-align: center;\n  font-weight: 700;\n  letter-spacing: -1px;\n  font-size: 20px;\n  color: #012a50;\n  text-decoration: none;\n}\n\n@media (max-width: 767px) {\n  .password {\n    width: 250px;\n  }\n\n  .logo {\n    margin-top: -50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profilePage": `style_profilePage__rPu48`,
	"container": `style_container__c2D0p`,
	"content": `style_content__e2xYy`,
	"logo": `style_logo__iZp-b`,
	"password": `style_password__3ijNF`,
	"link": `style_link__v1o4F`
};
export default ___CSS_LOADER_EXPORT___;
